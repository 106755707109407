html,
body {
  height: 100%;
  margin: 0;
  overscroll-behavior-y: contain;
}

#root {
  display: flex;
  height: inherit;
  overflow: auto;
}

div[class^="MuiTabs-flexContainer"] {
  justify-content: space-around;
}

.tacheEnded::after {
  content: "";
  position: absolute;
  border-top: 1px solid black;
  width: 20px;
  transform: rotate(315deg);
  transform-origin: 50% 0;
}

.Button-PlanOperateurFiche {
  background-color: transparent !important;
  box-shadow: none !important;
}

.Button-PlanOperateurFiche > span > svg {
  color: #ff732f;
}

.Button-Left > span > svg {
  -webkit-transform: rotate(180deg) scale(1.5);
  -moz-transform: rotate(180deg) scale(1.5);
  -ms-transform: rotate(180deg) scale(1.5);
  -o-transform: rotate(180deg) scale(1.5);
  transform: rotate(180deg) scale(1.5);
  filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.4));
}

.Button-Up > span > svg {
  -webkit-transform: rotate(270deg) scale(1.5);
  -moz-transform: rotate(270deg) scale(1.5);
  -ms-transform: rotate(270deg) scale(1.5);
  -o-transform: rotate(270deg) scale(1.5);
  transform: rotate(270deg) scale(1.5);
  filter: drop-shadow(-1px 0px 1px rgba(0, 0, 0, 0.4));
}

.Button-Down > span > svg {
  -webkit-transform: rotate(90deg) scale(1.5);
  -moz-transform: rotate(90deg) scale(1.5);
  -ms-transform: rotate(90deg) scale(1.5);
  -o-transform: rotate(90deg) scale(1.5);
  transform: rotate(90deg) scale(1.5);
  filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.4));
}

.Button-Right > span > svg {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
}

.HoverLink {
  color: #ff732f;
}

.AlertSmall > div[class^="MuiAlert-icon"] {
  margin-right: 0;
}

@media only screen and (min-width: 698px) {
  .button-procedures {
    margin: 1.5rem !important;
  }
}

@media only screen and (max-width: 698px) {
  .button-procedures {
    margin: 1.5rem 0 !important;
  }
}

@media only screen and (max-width: 521px) {
  .TypographyTitle-FicheConcession {
    padding-left: 15px;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0;
  }
  100% {
    background-position: 0 0;
  }
}
