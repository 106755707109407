.Accueil-Button-Grid {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Accueil-Button-FontAwesomeIcon {
  height: 5vh;
  width: 5vh !important;
}

.Accueil-Button-Typography {
  height: 60px;
}
